// @use "./color.scss" as color;

// @forward "./color.scss"; // Forward variables from color.scss

// @use "./font.scss";
// @use "./mediaQuery.scss";

// @use "./style.scss" as style;

@forward "./color.scss";  // Forward variables from color.scss
@forward "./font.scss";   // Forward variables from font.scss
@forward "./mediaQuery.scss";
@forward "./style.scss";  // Forward variables from style.scss


$font-main: "InterRegular";

body {
  font-family: $font-main;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

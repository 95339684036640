$base-font-size: 10px;
$base-font-weight: 400;
$base-font-family: "OpenSansRegular";

@mixin fontSize($font-size, $line-height: normal, $letter-spacing: normal) {
  font-size: $font-size;
  line-height: $line-height;
  letter-spacing: $letter-spacing;
}

@mixin fontWeight($size) {
  font-weight: $base-font-weight + $size;
}

@mixin fontFamily($fam) {
  font-family: $fam, $base-font-family;
}

@mixin fontStyle($size: false, $weight: false, $family: false, $color: false) {
  @if ($size) {
    font-size: $size;
  }
  @if ($weight) {
    font-weight: $weight;
  }
  @if ($family) {
    font-family: $family;
  }
  @if ($color) {
    color: $color;
  }
}

@import "./color.scss";

#components-layout-demo-fixed .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.2);
}
.drs-layout {
  .ant-layout-header {
    padding: 0 15px;
    height: 40px
  }
}
.drs-layout-content {
  position: relative;
  margin-top: 40px;
  padding-bottom: 40px;
  
  .drs-layout-component {
    margin: 0px;
    background: $whole-component-bg;
    text-align: center;
    border-radius: 6px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.19);
    background-color: $white-color;
    .dummy-component {
      min-height: calc(100vh - 350px);
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: capitalize;
      font-family: "OpenSansSemiBold";
      font-size: 30px;
      color: $nav-icon-inactive;
    }
  }
}

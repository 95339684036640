$maxmobile: 480px;
$minmobile: 320px;

$maxtablet: 767px;
$mintablet: 481px;

$maxipad: 920px;
$minipad: 768px;

$maxipadpro: 1199px;
$minipadpro: 921px;

$maxdesktop: 1400px;
$mindesktop: 1200px;

$minbigscreens: 1401px;

@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}
@mixin for-ipad {
  @media (max-width: #{$maxipad}) and (min-width: #{$minipad}) {
    @content;
  }
}
@mixin for-ipadpro {
  @media (max-width: #{$maxipadpro}) and (min-width: #{$minipadpro}) {
    @content;
  }
}

@import "../../assets/styles";

.reset-password-bg {
  min-height: 100vh;
  min-width: 100vw;
  margin: 0 0;
  background: $white-color;
  overflow-y: scroll;

  display: flex;
  align-items: center;
  justify-content: center;

  .ant-spin-nested-loading {
    min-width: 100%;
  }
}

.complete-reset-pw-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.complete-reset-pw-form > div {
  width: 100%;
}

.reset-password-wrapper {
  min-width: 80%;
}
.login-wrapper {
  display: flex;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center;
  // width: 1000px;

  & > div {
    width: 100%;
  }
}

.loader {
  display: flex;
  align-items: center;
  min-height: 100vh;
}

.loaderGifPosition {
  top: 45% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth-logo-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.auth-logo-img {
  display: block;
  text-align: center;
  height: 120px;
  width: 100%;
  margin-left: 5px;

  @include for-big-desktop-up {
    // height: 155px;
  }
  @include for-phone-only {
    text-align: center;
    height: 80px;
  }
  img {
    @include box(250px, 100px);
    @include for-big-desktop-up {
      @include box(250px, 100px);
    }
    @include for-phone-only {
      @include box(100px, 50px);
    }
  }
}

.login-header-text {
  color: $black-color;
  @include fontStyle(20px, 700, "OpenSansBold");
  @include for-phone-only {
    @include fontStyle(24px, 700, "OpenSansBold");
    text-align: center;
  }
  justify-self: center;
  align-self: center;
  margin-right: 15px;
}

.login-auth-wrapper {
  background: $white-color;

  .login-footer {
    display: flex;
    justify-content: center;
    h4 {
      padding: 0 10px;
      color: $login-footer-color !important;
    }
  }

  .ant-form-item-explain {
    @include fontStyle(12px, 500, "OpenSansSemiBold");
  }

  .ant-input {
    border-radius: 6px;
    @include fontStyle(14px, 500, "OpenSansSemiBold");
  }
  .emailInput,
  .passwordInput {
    ::placeholder {
      color: $input-border-color;
      @include fontStyle(12px, 500, "OpenSansSemiBold");
    }
    .ant-form-item-control-input,
    .ant-input-affix-wrapper {
      &:hover,
      &:focus {
        border-radius: 6px;
        box-shadow: 0 4px 10px 3px rgba(0, 0, 0, 0.11);
      }
    }
    &:hover,
    &:focus {
      ::placeholder {
        color: $hover-placholder-color;
      }
    }
  }
  .ant-input-password-icon {
    @include fontSize(11px);
    color: $black-color;
  }
}
.login-auth-area {
  @include for-tablet-portrait-up {
    padding: 40px 0;
  }
  @include for-phone-only {
    padding: 20px 0;
  }
  h4 {
    color: $text-color;
    @include fontStyle(14px, 100, "OpenSansSemiBold");
  }
  .rememberLabel,
  .forgot-password {
    color: $text-color;
    @include fontStyle(14px, 100, "OpenSansSemiBold");
  }
  .forgot-password {
    color: $btn-bg-color;
    float: right;
  }
  .divider {
    margin: 20px 0 0 0;
    background: $login-divider-color;
    border: none;
    height: 1px;
  }
  .login-form {
    button {
      width: 100%;
      @include fontStyle(14px, 600, "OpenSansSemiBold");
      padding: 10px;
      height: 44px;
    }
    .ant-btn-primary {
      @extend .antd-btn-override;
    }
    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background: $btn-bg-color;
        border-color: $btn-bg-color;
      }
    }
    .ant-input {
      border-radius: 6px;
      padding: 10px;
    }

    .ant-input-affix-wrapper {
      border-radius: 6px;
      padding: 0 10px 0 0;
    }
    svg {
      color: $svg-color;
    }
  }
}

.request-pass-home-link{
  display: flex;
  justify-content: center;
}
@import "../../assets/styles";

$bg-img-url: "../../assets/images/left-img.png";

@mixin imageText {
  position: absolute;
  left: 15%;
  display: flex;
  align-items: center;
  color: $white-color;
}

.auth-img {
  min-height: 100vh;
  .img-bg {
    background-image: url($bg-img-url);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    min-height: 100vh;
  }
  .text-over-img1 {
    @include imageText;
    @include fontStyle(50px, 900, "OpenSansExtraBold");
    @include for-big-desktop-up() {
      @include fontStyle(60px, 900, "OpenSansExtraBold");
    }
    top: 15%;
    p {
      margin-bottom: 5px;
      text-shadow: 0 1px 1px $black-color;
      text-align: left;
    }
  }
  .text-over-img2 {
    @include imageText;
    @include fontStyle(16px, 700, "OpenSansBold");
    bottom: 30px;
    p {
      text-align: center;
      margin-bottom: 5px;
    }
  }
}

.alert-main-div {
  margin: 10px 0;
  .alert-main-div {
    padding: 4px 10px;
    border-radius: 6px;
    font-size: 11px;
  }
}

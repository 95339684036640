@use "../../assets/styles/index.scss" as styles;

.error-page-main {
  height: 100vh;
  min-height: 100vh;
  background-color: styles.$input-bg-color;
  .drs-main-logo {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    img {
      // margin: 20px;
      width: 100px;
      height: 50px;
    }
  }
  .ant-result-title {
    font-family: "OpenSansBold";
  }
  .ant-result-subtitle {
    font-family: "OpenSansBold";
    color: styles.$main-bg-color;
  }
  .error-page-action-btn {
    @extend .antd-btn-override;
    font-family: "OpenSansSemiBold";
  }
}

$white-color: #ffffff;
$black-color: #000000;
$footer-font-color: #8a8a8a;
$main-bg-color: #23a0a8;
$dark-black: #333333;
$light-grey: #cccccc;
$placeholder-color: #a0a0a0;
$btn-primary-bg-color: #25add5;
$input-bg-color: #f1f1f1;
$grey-border-color: #c7c7c7;
$grey-card-bg-color: #f4f4f4;
$light-grey-color: #f2f2f2;
$svg-color: #b8b8b8;
$btn-hover: #ffffff;
$login-divider-color: #e8e3e3;
$primary-color: #1565d8;
$black-transparent-19: rgba(0, 0, 0, 0.19);
$text-color: #696f79;
$link-color: #095aa4;
$input-border-color: #d0d0d0;
$hover-placholder-color: #494949;
$btn-bg-color: #10a2ce;
$login-footer-color: #8692a6;

$sub-header-card-side: #dbdbdb;
$sub-header-card-bg: #f4f4f4;
$sub-header-card-border: #c7c7c7;

$whole-component-bg: #f2f5f7;

$component-btn-clr: #25add5;

$table-header-color: #eeeeee;

$table-td-color: #444444;

$table-row-hover: #f0f8f9;

$image-wrapper-color: #d9dae4;

$table-th-td-border-clr: #d9d7d7;

$disabled-font-clr: #a3a3a3;

$table-svg-action-icon: #a1b0b1;
$table-svg-action-icon-hover: #58b2cd;

$nav-icon-inactive: #97a8a9;
$alert-text: #172b4d;
$alert-bg: #ffebe6;
$grey-bg: #e5e5e5;
$cancel-btn-clr: #717171;
$cancel-btn-brd: #dfe2e8;

$api-success-bg: #f6ffed;
$api-success-brd: #b7eb8f;
$api-error-bg: #fff2f0;
$api-error-brd: #ffccc7;

$calendar-header-clr: #27adb6;
